<template>
  <v-btn
    x-large
    @click.prevent="sendCmd()"
    @click.native.prevent
    :color="color"
    :dark="color ? true : false"
    fab
    elevation="2"
  >
    <v-icon large> {{ icon }} </v-icon>
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Button",
  computed: {
    ...mapState("roomControl", ["targetId"]),
    color() {
      if (this.control.type === "blind") {
        return "";
      }
      let nameLower = this.control.name.toLowerCase();
      if (nameLower.endsWith("down") || nameLower.endsWith("aus")) {
        return "red";
      } else if (nameLower.endsWith("up") || nameLower.endsWith("ein")) {
        return "green";
      }
      return "";
    },
    icon() {
      let nameLower = this.control.name.toLowerCase();
      if (nameLower.endsWith("down") || nameLower.endsWith("aus")) {
        return this.control.type === "blind"
          ? "mdi-arrow-down"
          : "mdi-power-off";
      } else if (nameLower.endsWith("up") || nameLower.endsWith("ein")) {
        return this.control.type === "blind" ? "mdi-arrow-up" : "mdi-power-on";
      }
      return "mdi-power";
    },
  },
  methods: {
    sendCmd() {
      this.$store.dispatch("roomControl/setRoomControl", {
        item: {
          targetId: this.targetId,
          targetOptions: this.control.targetOptions,
        },
      });
    },
  },
  props: {
    control: {
      type: Object,
      default: undefined,
    },
  },
};
</script>
