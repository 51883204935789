<template>
  <v-row justify="center" align="center">
    <v-col cols="12"
      ><v-subheader>{{ title }}</v-subheader></v-col
    >
    <v-col cols="6" class="d-flex justify-center">
      <v-btn
        x-large
        outlined
        @click.prevent="sendCmd('ab')"
        @click.native.prevent
        fab
      >
        <v-icon large>mdi-arrow-down</v-icon>
      </v-btn></v-col
    >
    <v-col cols="6" class="d-flex justify-center"
      ><v-btn
        x-large
        outlined
        @click.prevent="sendCmd('auf')"
        @click.native.prevent
        fab
      >
        <v-icon large>mdi-arrow-up</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Shades",
  methods: {
    sendCmd(cmd) {
      let cmdName = this.groupCommand + "_" + cmd;
      let item = this.$store.state.roomControl.items.find(
        (e) => e.name === cmdName
      );
      this.$store.dispatch("roomControl/setRoomControl", { item });
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    groupCommand: {
      type: String,
      default: "",
    },
  },
};
</script>
