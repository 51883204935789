<template>
  <v-container>
    <v-row align="center">
      <v-spacer></v-spacer>
      <v-col cols="2">
        <div class="text-h4 text-no-wrap">
          {{ valueText }}
        </div>
      </v-col>
      <v-col cols="2">
        <div
          v-if="control.title.toLowerCase().includes('control')"
          class="text-h4 text-no-wrap"
        >
          {{ $t("roomControl.tempUnitC") }}
        </div>
        <v-icon
          v-else-if="control.title.toLowerCase().includes('fanspeed')"
          large
          color="#000000DE"
        >
          mdi-weather-windy
        </v-icon>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="8">
        <v-slider
          v-model="currentValue"
          :step="step"
          :min="min"
          :max="max"
          @end="sendCmd(currentValue)"
          hide-details="true"
          :tick-labels="tickLabels"
          tick-size="0"
        >
        </v-slider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Slider",
  data() {
    return {
      currentValue: parseInt(this.defaultValue) || parseInt(this.control.min),
    };
  },
  computed: {
    ...mapState("roomControl", ["targetId"]),
    min() {
      return parseInt(this.control.min);
    },
    max() {
      return parseInt(this.control.max);
    },
    step() {
      return parseInt(this.control.step);
    },
    valueText() {
      return this.currentValue.toFixed(this.countDecimals(this.step));
    },
    tickLabels() {
      let ticks = [];
      for (let i = this.min; i <= this.max; i += this.step) {
        ticks.push(i % (this.step * 4) === this.min % 4 ? i + "" : "");
      }
      return ticks;
    },
  },
  methods: {
    countDecimals(v) {
      if (Math.floor(v) == v) {
        return 0;
      }
      return v.toString().split(".")[1].length;
    },
    sendCmd(value) {
      this.$store.dispatch("roomControl/setRoomControl", {
        item: {
          value,
          targetId: this.targetId,
          targetOptions: this.control.targetOptions,
        },
        state: {
          commandId: this.control.id,
          state: value,
        },
      });
    },
  },
  props: {
    control: {
      type: Object,
      default: undefined,
    },
    defaultValue: {
      type: String,
      default: "",
    },
  },
};
</script>
