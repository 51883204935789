<template>
  <div>
    <!-- roomControl.protocol values are defined in atv-cms-service-go -> oneapp.json -->
    <v-container v-if="this.settings.roomControl.protocol === 'KnX'">
      <h1>{{ $t("app.roomControl") }}</h1>
      <div class="mt-4">
        <v-select
          v-if="$store.state.roomControl.types.length > 0"
          :items="$store.state.roomControl.types"
          v-model="selectedType"
          return-object
          item-text="title"
          :label="$t('roomControl.selectControl')"
          single-line
          auto
          required
          autocomplete
          prepend-icon="mdi-television"
        ></v-select>
        <template v-if="selectedType && selectedType.key === 'light'">
          <lights
            v-for="(group, index) in controlGroups"
            :key="'group' + index"
            :title="group[0].groupName"
            :groupCommand="group[0].groupCommand"
          ></lights>
        </template>
        <template v-else-if="selectedType">
          <shades
            v-for="(group, index) in controlGroups"
            :key="'group' + index"
            :title="group[0].groupName"
            :groupCommand="group[0].groupCommand"
          ></shades>
        </template>
        <v-row v-if="selectedControls.length > 0"> </v-row>
        <v-alert
          class="mt-4"
          v-if="
            $store.state.roomControl.statusGet === 'failure' ||
            ($store.state.roomControl.statusGet !== 'fetching' &&
              $store.state.roomControl.items.length < 1)
          "
          type="error"
        >
          {{ this.$t("roomControl.loadRctrlFailed") }}
        </v-alert>
      </div>
    </v-container>
    <v-container v-else>
      <h1>{{ $t("app.roomControl") }}</h1>
      <div class="mt-4">
        <v-select
          v-if="$store.state.roomControl.types.length > 0"
          :items="$store.state.roomControl.types"
          v-model="selectedType"
          return-object
          item-text="title"
          :label="$t('roomControl.selectControl')"
          single-line
          auto
          required
          autocomplete
          :prepend-icon="
            selectedType ? getTypeIcon(selectedType.key) : 'mdi-television'
          "
        >
          <template slot="item" slot-scope="data">
            <v-icon> {{ getTypeIcon(data.item.key) }} </v-icon>
            <div class="pl-2">{{ data.item.title }}</div>
          </template>
        </v-select>
        <v-row>
          <v-col
            cols="12"
            v-if="selectedType"
            v-for="(group, index) in controlGroups"
            :key="'group' + index"
          >
            <v-card outlined>
              <v-card-title>{{ translateGroupName(index) }}</v-card-title>
              <v-card-actions>
                <v-container>
                  <v-row justify="space-around">
                    <v-col
                      v-for="control in sortGroup(group)"
                      :key="control.id"
                      :cols="control.selectionType === 'slider' ? '12' : '4'"
                      class="text-center"
                    >
                      <Button
                        v-if="control.selectionType === 'button'"
                        :control="control"
                      ></Button>
                      <Slider
                        v-if="control.selectionType === 'slider'"
                        :control="control"
                        :defaultValue="defaultValue(control)"
                      ></Slider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Button from "@/components/roomControl/loxone/Button";
import Slider from "@/components/roomControl/loxone/Slider";
import Lights from "@/components/roomControl/Lights";
import Shades from "@/components/roomControl/Shades";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
export default {
  data: () => ({
    lastCommand: undefined,
  }),
  mounted() {
    this.$store.dispatch("roomControl/getRoomControl");
  },
  components: {
    Button,
    Slider,
    Lights,
    Shades,
  },
  computed: {
    ...mapState("session", ["settings", "authResult"]),

    selectedType: {
      get() {
        return this.$store.state.roomControl.selectedType;
      },
      set(val) {
        this.$store.commit("roomControl/SET_TYPE", val);
      },
    },
    controlGroups() {
      let groups = this.selectedControls.map((item) => {
        let names = item.title.split(" ");
        let groupName = names[0] + " " + names[1];
        let commands = item.name.split("_");
        let groupCommand = commands[0] + "_" + commands[1];
        return {
          ...item,
          groupName,
          groupCommand,
        };
      });
      groups = groupBy(groups, "groupName");
      return groups;
    },
    ...mapGetters("roomControl", ["selectedControls"]),
  },
  methods: {
    getTypeIcon(typeKey) {
      switch (typeKey) {
        case "light":
          return "mdi-lightbulb-on";
        case "temperature":
          return "mdi-thermometer";
        case "fanspeed":
          return "mdi-fan";
        case "blind":
          return "mdi-blinds";
        case "marquise":
          return "mdi-storefront";
        default:
          return "mdi-television";
      }
    },
    sortGroup(group) {
      return sortBy(group, ["selectionType", "name"]);
    },
    translateGroupName(name) {
      name = name.toLowerCase();
      let nameWords = name.split(" ");
      return (
        this.$t("roomControl." + nameWords[0]) +
        " " +
        this.$t("roomControl." + nameWords[1])
      );
    },
    defaultValue(control) {
      try {
        const val = `${this.authResult.checkin[0].room}-${control.id}`;
        return this.$store.state.roomControl.state[val].state;
      } catch (e) {
        return "";
      }
    },
  },
};
</script>
