<template>
  <v-row justify="center" align="center">
    <v-col cols="12"
      ><v-subheader>{{ title }}</v-subheader></v-col
    >
    <v-col cols="4" class="d-flex justify-center">
      <v-btn
        x-large
        @click.prevent="sendCmd('0')"
        @click.native.prevent
        color="red"
        dark
        fab
      >
        <v-icon large>mdi-power</v-icon>
      </v-btn></v-col
    >
    <v-col cols="4" class="d-flex justify-center">
      <v-btn
        x-large
        outlined
        @click.prevent="sendCmd('-')"
        @click.native.prevent
        fab
      >
        <v-icon large>mdi-minus</v-icon>
      </v-btn></v-col
    >
    <v-col cols="4" class="d-flex justify-center"
      ><v-btn
        class="ma-0 d-flex"
        x-large
        outlined
        @click.prevent="sendCmd('+')"
        @click.native.prevent
        fab
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Lights",
  data() {
    return {
      currentValue: 3,
    };
  },
  methods: {
    sendCmd(cmd) {
      if (cmd === "+") {
        if (this.currentValue <= 0) {
          this.currentValue = 3;
        } else {
          this.currentValue = Math.min(this.currentValue + 1, 3);
        }
      } else if (cmd === "-") {
        this.currentValue = Math.max(this.currentValue - 1, 0);
      } else {
        this.currentValue = 0;
      }

      let cmdName = this.groupCommand + "_" + this.currentValue;
      let item = this.$store.state.roomControl.items.find(
        (e) => e.name === cmdName
      );
      this.$store.dispatch("roomControl/setRoomControl", { item });
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    groupCommand: {
      type: String,
      default: "",
    },
  },
};
</script>
